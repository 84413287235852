const modifyQuantity = (event) => {
  const modifyButton = event.target;
  const allowBelowZero = modifyButton.getAttribute('data-allow-below-zero');
  const modifyField = modifyButton.getAttribute('data-field');
  const modifyValue = Number.parseFloat(modifyButton.getAttribute('data-value'));
  const row = modifyButton.closest('tr');
  let quantity = Number.parseFloat(row.querySelector(`input[name="${modifyField}"]`).value.replace(',', '.'));

  if (Number.isNaN(quantity)) {
    row.querySelector(`input[name="${modifyField}"]`).value = modifyValue;
  } else {
    quantity += modifyValue;

    if (allowBelowZero === 'false' && quantity < 0) {
      quantity = 0.0;
    }

    row.querySelector(`input[name="${modifyField}"]`).value = quantity.toFixed(4).replace('.', ',');
  }
  row.querySelector(`input[name="${modifyField}"]`).dispatchEvent(new Event('change'));
};

const init = () => {
  const modifyQuantityButtons = document.querySelectorAll('button.modify-quantity');

  Array.from(modifyQuantityButtons).forEach((button) => {
    button.addEventListener('click', modifyQuantity);
  });
};

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    init();
  });
};
